import { render, staticRenderFns } from "./Login-coach.vue?vue&type=template&id=0ba4c600&scoped=true"
import script from "./Login-coach.vue?vue&type=script&lang=js"
export * from "./Login-coach.vue?vue&type=script&lang=js"
import style0 from "./Login-coach.vue?vue&type=style&index=0&id=0ba4c600&prod&lang=scss"
import style1 from "../css/pages/login.scss?vue&type=style&index=1&id=0ba4c600&prod&scoped=true&lang=scss&external"
import style2 from "../css/pages/login-coach.scss?vue&type=style&index=2&id=0ba4c600&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba4c600",
  null
  
)

export default component.exports