<template>
		<div><a target="_blank" class="name mobile-title" href=""><span><img src="../news-img/logo_red.png" class="logo-letter" alt="image de la lettre m"/>ENTOR</span></a>
		<nav id="login-nav">
			<div id="espace-tau">
				<a target="_blank" class="name" href="https://mentorapp.fr"><span><img src="../news-img/logo_red.png" class="logo-letter" alt="image de la lettre m"/>ENTOR</span></a>
				<!--a target="_blank" href="https://espacetemps.io/#part0-intro"><img src="../public/img/espace-tau-b-transparent.png"/></a-->
				<div v-if="isDemo" id="pro">DEMO</div>
				<div v-else id="pro">PRO</div>
			</div>
			<a id="join" target="_blank" href="https://mentorapp.fr/subscribe">Nous rejoindre</a>
		</nav>
		<div  class="log">
			<div class="col-left">
				<div id="form-wrapper">
					<div id="title-wrapper">
						<h1 class="title-login" :class="{ forgot: forgot }">{{ forgot ? 'mot de passe oublié ?' : 'connexion' }}</h1>
					</div>
					<div id="base" :class="{ forgot: forgot }">
						<form id="form-login" @submit.prevent="">
							<div class="form-group-new">
								<div class="input-borders-new">
									<div class="input-group-new">
										<input
											class="form-control-new"
											name="gaiaid"
											:placeholder="$t('common.clientid')"
											autocomplete="organization"
											@keyup.enter="$refs.login.click"
											type="text"
											v-model="gaiaid"/>
									</div>
								</div>
							</div>
							<div class="form-group-new">
								<div class="input-borders-new">
									<div class="input-group-new">
										<input class="form-control-new" name="user" :placeholder="$t('common.usern')" autocomplete="off" @keyup.enter="$refs.login.click" type="text" v-model="user"/>
									</div>
								</div>
							</div>
							<div class="form-group-new">
								<div class="input-borders-new">
									<div class="input-group-new">
										<input class="form-control-new" name="password" :placeholder="$t('common.pwd')" autocomplete="off" @keyup.enter="$refs.login.click" type="password" v-model="password"/>
									</div>
								</div>
							</div>
							<!--
							<input class="form-control" name="gaiaid" :placeholder="$t('common.clientid')" autocomplete="organization" @keyup.enter="$refs.login.click" type="text" v-model="gaiaid">
							<input class="form-control" name="user" :placeholder="$t('common.usern')" autocomplete="off" @keyup.enter="$refs.login.click" type="text" v-model="user">
							<input class="form-control" name="password" :placeholder="$t('common.pwd')" autocomplete="off" @keyup.enter="$refs.login.click" type="password" v-model="password">
							-->
							<div class="buttons">
								<div class="checkbox checkbox-primary">
									<input id="remember" class="styled d-none" name="remember" type="checkbox" v-model="rememberMe">
									<label class="d-inline-block" for="remember">
										<div class="checkbox-text">{{$t("common.remember")}}</div>
									</label>
								</div>
								<StatusButton id="connexion" ref="login" type="login" alert="modal" @click="login" attrType="submit">Connexion</StatusButton>
							</div>
							<div :class="'alert-wrapper' + ( error !== '' ? ' visible' : '' )">
								<div class="alert alert-danger">{{error}}</div>
							</div>
							<div id="subscribe-wrapper">
								<a id="subscribe" target="_blank" href="https://mentorapp.fr/subscribe">Vous n'avez pas de compte ? <u>Par ici !</u></a>
							</div>
						</form>
						<div id="btn-forgot" @click="success = ''; error = ''; forgot = true">{{$t("common.forgotpwd")}}</div>
						<form id="form-forgot" :class="{ visible: forgot }" @submit.prevent="">
							<h2>{{ $t("common.forgotpwd") }}</h2>
							<input class="form-control" name="gaiaid" :placeholder="$t('common.clientid')" autocomplete="organization" @keyup.enter="$refs.reset.click" type="text" v-model="reset.gaiaid">
							<input class="form-control" name="email" :placeholder="$t('common.email')" autocomplete="off" @keyup.enter="$refs.reset.click" type="text" v-model="reset.email">
							<div class="buttons">
								<button id="btn-cancel-forgot" class="btn btn-gray" @click="error = ''; forgot = false">{{ $t('common.cancel') }}</button>
								<StatusButton id="btn-send" ref="reset" type="login" alert="modal" @click="sendForgot">Envoyer</StatusButton>
							</div>
							<div :class="'alert-wrapper' + ( ( error !== '' || success !== '' ) ? ' visible' : '' )">
								<div v-if="error" class="alert alert-danger">{{error}}</div>
								<div v-else-if="success" class="alert alert-success">{{success}}</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="col-right mobile-none">
				<div class="bg-img">
					<!--div class="parrainage">
						<div class="subtitle">
						OFFRE  <span class="purpleword">PARRAIN</span>AGE
						</div>
						<div data-aos="zoom-in" data-aos-delay="600" data-aos-duration="2000"  class="price">
							<span class="purpleword">0€</span>/MOIS
						</div>
						<h3 class="text">
							Parraine un autre coach
							et</br>
							<span class="redword"><b>gagnez</b></span>
							tous les deux
							<span class="redword"><b>1 mois d'abonnement</b></span>* !
						</h3>
					</div>
					<a target="_blank" href="https://motivapp.fr"><img id="logo" src="../img/motiv.png"/></a> -->
				</div>
			</div>
		</div>
		<div id="browser"></div>
		<!-- <a id="apple-store"><img src="../public/img/apple_store.png"/></a> -->
		<!-- <a id="google-play"><img src="../public/img/google_play.png"/></a> -->
	</div>
</template>

<script>
	import StatusButton from '../components/StatusButton.vue'

	export default {
		components: {
			StatusButton
		},
		data() {
			return {
				gaiaid: window.localStorage.getItem( "gaiaid" ),
				user: "",
				password: "",
				error: "",
				success: "",
				forgot: false,
				reset: {
					gaiaid: window.localStorage.getItem( "gaiaid" ),
					email: ""
				},
				rememberMe: true,
			}
		},
		watch: {
			'$route'( to, from ) {
				if ( to.path.indexOf( "/login" ) < 0 ) {
					this.user = ""
					this.password = ""
				}
			}
		},
		computed: {
			isDemo() {
				return process.env.VUE_APP_DEMO
			},
			inBrowser() {
				return false // TODO : remove me in an undetermined future
			//	return ( typeof cordova === 'undefined' )
			},
			inBrowser2() {
				return ( typeof cordova === 'undefined' )
			}
		},
		methods: {
			async login( callback ) {
				this.error = ''
				this.user = this.user.trim()
				if ( !this.gaiaid || !this.user || !this.password ) {
					callback( -1 )
					return
				}
				if ( this.user.toLowerCase() === "salon" ) {
					this.user = "general"
				}
				try {
					const { data } = await this.$api.login(
						parseInt(this.gaiaid),
						this.user.toLowerCase(),
						this.password,
						this.rememberMe
					)

					callback( true )
					await this.$store.dispatch( "reload", [data.domain, data.salonid, data.workerid] )
					this.$nextTick( () => {
						this.$router.push( "/dashboard" )
					})

				} catch (error) {
					callback( false )
					console.error( error )
					if ( error.response.data.error ) {
						this.error = this.$t( "errors." + error.response.data.error )
					} else {
						this.error = error.response.data
					}
				}
			},
			sendForgot( callback ) {
				if ( !this.reset.gaiaid || !this.reset.email ) {
					callback( -1 )
					return
				}
				const email = this.$t( "emails.forgotpwd" )
				this.$api.post( "/esthetics/workers/forgot", { gaiaid: this.reset.gaiaid, email: this.reset.email, subject: email.subject, html: email.body } ).then( response => {
					callback( true )
					this.error = ""
					this.success = this.$t( "common.requestsent" )
					setTimeout( () => {
						this.success = ""
						this.forgot = false
					}, 1000 )
				}).catch( error => {
					callback( false )
					console.log( error )
					if ( error.response.data.error ) {
						this.error = this.$t( "errors." + error.response.data.error )
					} else {
						this.error = error.response.data
					}
				})
			}
		}
	}
	
</script>

<style lang="scss">
	@import 'espace-theme/css/utils.scss';

	body.login.coachs {
		padding: 0;
/*		background-image: linear-gradient(76.8deg, #792d81 3.6%, #241f62 90.4%);*/
		background-image: linear-gradient(155deg, #ffffff 15%, #d0d0d0 200% );
/*		@include fixed-background( "../public/img/stars.png" );*/
	}

	#google-play, #apple-store {
		display: none;
	}

	#back.browser2 {
		@media screen and ( min-width: 960px ) {
			#google-play, #apple-store {
				display: block;
			}
		}
		#btn-forgot {
			left: 0;
			right: unset !important;
			color: black !important;
		}
	}
</style>
<style scoped lang="scss" src="../css/pages/login.scss"/>
<style scoped lang="scss" src="../css/pages/login-coach.scss"/>

